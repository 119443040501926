@import '../node_modules/@kf-products-core/kfhub_lib/styles/reset.scss';
@import '../node_modules/@kf-products-core/kfhub_lib/_deprecated/_kfcore-less/fonts/proxima-nova/proxima-nova.scss';
@import '../node_modules/@kf-products-core/kfhub_lib/_deprecated/_kfcore-less/fonts/kf-icons/style.css';
@import '../node_modules/@kf-products-core/kfhub_lib/_deprecated/_kfcore-less/deep-component-overrides.scss';
@import '../node_modules/@kf-products-core/kfhub_lib/_deprecated/_kfcore-less/growl.scss';
@import '../node_modules/@kf-products-core/kfhub_lib/styles/variables.scss';
@import '../node_modules/@kf-products-core/kfhub_lib/styles/mixins.scss';

// bootstrap base
@import '../node_modules/@kf-products-core/kfhub_lib/styles/bootstrap/bootstrap.scss';
// bootstrap input
@import "forms";

@import "../node_modules/codemirror/lib/codemirror.css";
@import "../node_modules/codemirror/theme/material.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

@import '../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css'; 
@import '../node_modules/@kf-products-core/kfhub_lib/styles/datepicker/datepicker.scss'; 

html {
	background-color: white;
}

*:focus {
	outline: none !important;
}


.panel-background {
	background: #fafafb !important;
	margin: 0px 0px;
	min-width: 600px !important;
}

div.p-table-caption {
	padding: 0.25em 1.0em !important;
}



tr.custom-table-header {
	th {
		padding: 0.25em 1.0em !important;
	}
}

tr.custom-table-body {
	td {
		padding: 0.1em 1.0em !important;

		div.action {
			button {
				margin: 0px;
			}
		}
	}
}

.common-header {
	font-weight: 700;

	div.add-new {
		display: flex;

		div.refresh-icon {
			i {
				border-radius: 50%;
				margin-right: 3%;
				font-size: 1.3em;
				color: #157da4;
				padding: 0.6%;
				margin-bottom: 1%;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: horizontal;
				-webkit-box-direction: normal;
				-ms-flex-direction: row;
				flex-direction: row;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: end;
				-ms-flex-pack: end;
				justify-content: flex-end;
				cursor: pointer;
				margin-top: 8px;
				margin-left: 10px;
			}

		}
	}

}

.text-overflowCss {
	overflow-wrap: break-word;
}

// body.p-overflow-hidden{
//   overflow: auto;
// }

.p-tooltip-arrow {
	display: none;
}
body .p-tooltip .p-tooltip-text {
	// background-color: aliceblue !important;
	background-color: rgba(195, 213, 0, 1) !important;
	// background-color: rgba(17, 101, 80, 1) !important;
	color: #000 !important;
	text-align: left !important;
	padding: 10px !important;
	line-height: 1.5;
}

body .invalid-link-tooltip .p-tooltip-text{
	  background-color: #ff4e4e !important;
	  color: #fff !important;
	  text-align: left !important;
	  padding: 10px !important;
	  line-height: 1.5;
	  border-radius: 12px !important;
	  color: #fff !important;
	  font-size: 14px !important;
  }



body .p-menu .p-menuitem {
	padding: 8px !important;
	font-size: 0.94em !important;

}

body .p-dialog.p-widget.p-widget-content.p-corner-all.p-shadow.p-dialog-draggable.ng-trigger.ng-trigger-dialogState {
	padding: 33px !important;
}

.kf-icon-info {
	font-size: 16px !important;
	color: gray !important;
}

.my-modal {
	max-width: 96% !important;
	margin: 1% auto !important;
}

.my-modal-confirm {
	max-width: 26% !important;
	margin: 10% auto !important;
}

//:host ::ng-deep {
.slider-container {
	.slider-header {
		margin: 25px 0 0 !important;
	}
}

//}


// Override Prime ng theme 

body {
	.p-tabview {
		.p-tabview-panels {
			border: none;
			padding: 0.571em 0;
		}
	}

	.p-accordion-tab {
		.p-accordion-header {
			&:not(.p-state-disabled).p-state-active {
				&:hover {
					a {
						border: 1px solid #c8c8c8;
						background-color: #f4f4f4;
						color: #000;
					}
				}

				a {
					border: 1px solid #c8c8c8;
					background-color: #f4f4f4;
					color: #000;
				}
			}
		}
	}

	.p-corner-all {
		border-radius: 3px;
	}

	.p-inputgroup {
		.p-inputgroup-addon {
			padding: 0;

			&:first-child {
				border-top-left-radius: 3px;
				border-bottom-left-radius: 3px;
			}

			border-color: #d6d6d6;
			min-width: 6em !important;
		}
	}

	.p-inputgroup .p-inputtext {
		width: 100%;
	}

	.p-tabview.p-component {
		ul.p-tabview-nav {
			li.p-highlight {
				border: none;
				color: #000;
				outline: none;
				box-shadow: none;
				a {
					color: #000;
					background-color: inherit;
					border: none;
					outline: none;
					box-shadow: none;

					.p-tabview-title {
						border-bottom: 1px solid #000 !important;
						padding-bottom: 3px;
						font-weight: 600;
					}

					&:focus {
						outline: none;
						box-shadow: none;
					}
				}
			}

			li {
				border-bottom: none !important;
				margin-right: 25px;
				margin-bottom: 0.8rem;

				a {
					padding: 0.571em 0.50em;
					font-weight: 400;
					letter-spacing: 0.15em;
					font-size: 1em;
					color: #000;
					background-color: inherit;
					border: none;
				}

			}
		}
	}

	.p-tabview.p-tabview-bottom {
		.p-tabview-nav {
			li.p-state-active {
				background-color: #fff;
				border: none;
				color: #000;
				border-bottom: 1px solid #000;
				outline: none;
				box-shadow: none;

				a {
					color: #000;
				}
			}

			li {
				a {
					padding: 0.571em 0.50em;
					font-weight: 300;
					letter-spacing: 0.25em;
				}
			}
		}
	}

	.p-tabview.p-tabview-left {
		.p-tabview-nav {
			li.p-state-active {
				background-color: #fff;
				border: none;
				color: #000;
				border-bottom: 1px solid #000;
				outline: none;
				box-shadow: none;

				a {
					color: #000;
				}
			}

			li {
				a {
					padding: 0.571em 0.50em;
					font-weight: 300;
					letter-spacing: 0.25em;
				}
			}
		}
	}

	.p-tabview.p-tabview-right {
		.p-tabview-nav {
			li.p-state-active {
				background-color: #fff;
				border: none;
				color: #000;
				border-bottom: 1px solid #000;

				&:focus {
					outline: none;
					box-shadow: none;
				}

				a {
					color: #000;
				}
			}

			li {
				a {
					padding: 0.571em 0.50em;
					font-weight: 300;
					letter-spacing: 0.25em;
				}
			}
		}
	}

	.p-table {
		.p-sortable-column.p-state-highlight {
			background: #186ba0;

			&:focus {
				background: #186ba0;
				outline: none;
				box-shadow: none;
			}
		}

		.p-table-thead {
			>tr {
				>th {
					padding: 0.571em 0.657em;
					border: 1px solid #c8c8c8;
					font-size: 0.840em;
					font-weight: 700;
					color: #333333;
					background-color: #f4f4f4;
					text-transform: uppercase;
				}
			}
		}
	}

	.p-paginator {
		.p-paginator-pages {
			.p-paginator-page.p-state-active {
				&:focus {
					background: #186ba0;
					border-color: #186ba0;
					color: rgb(255, 255, 255);
					outline: none;
					box-shadow: none;
					cursor: pointer;
				}
			}
		}

		.p-dropdown {
			height: inherit;
			border: 1px solid #d6d6d6;

		}
	}

	.p-inputtext {
		border: 1px solid #d6d6d6;
	}

	.p-dropdown {
		width: 100%;

		.p-dropdown-label-container {
			overflow: hidden;
		}

		.p-dropdown-label {
			width: 98% !important;
		}
	}
	
	.input-group-text {
		height: 37px !important;
	}

	.p-placeholder {
		color: gray;
	}

	.p-dialog {
		.p-dialog-titlebar {
			border: none;
		}

		.p-dialog-footer {
			border: none;
			display: flex;
			justify-content: flex-end;
			padding: 1.2rem;

			button {
				margin-left: 5px;
			}
		}

	}
.p-datepicker {
		table {
			td {
				padding: 0 !important;
			}
		}
	}
}

html {
	body {
		.p-button.p-button-icon-only {
			width: 2rem !important;
			padding: 0.329rem 0px;
		}

		.p-accordion-tab {
			.p-accordion-header {
				background: #f4f4f4;
				border-bottom: none;
				border: 1px solid #c8c8c8;

				>a {
					padding: 8px 8px;
					border-radius: 0;
				}

				.p-accordion-header-link {
					padding: 0.6rem;
					border: none;
					outline: none;

					&:focus {
						outline: 0 none;
						box-shadow: none;
					}
				}
			}
		}

		.p-menu {
			width: 15.5em !important;

			.p-menuitem,
			.p-menuitem-text,
			.menuitem {
				&:hover {
					color: #fff !important;
				}
			}

			.p-menuitem-text {
				line-height: 1.2 !important;
			}
		}

		p-confirmdialog .p-dialog-visible {
			// justify-content: space-around;
		}

		.p-dialog {
			padding: 0px !important;
			min-width: 30vw;
		}

		.p-paginator {
			.p-dropdown {
				min-width: 4.4em !important;
			}
		}

		.p-chkbox {
			margin-right: 7px !important;
		}

		.p-paginator {
			.p-paginator-pages {
				.p-paginator-page {
					min-width: 2.886em !important;
				}
			}
		}

		.p-multiselect-panel {
			.p-multiselect-items {
				.p-multiselect-item {
					display: flex !important;
				}
			}
		}

	}
}

.pi-chevron-down {
	&:before {
		color: gray;
	}
}

button {

	margin-right: 2px !important;

	&:disabled {
		background-color: lightgray !important;
	}

	&:focus {
		outline: 0;
	}
}

div.action {
	button.small-button {
		background-color: #186ba0;
		border: 1px solid #186ba0;
	}
}

.fa-list {
	&:before {
		color: #fff;
	}
}

.pi-exclamation-triangle {
	&:before {
		color: red;
	}
}

.p-confirmdialog {
	.p-dialog-content {
		.p-confirmdialog-icon {
			top: -0.5em;
		}

	}
}

// Common.css
// *primeng 5 styles to fix them after migration on primeng 9
.p-placeholder {
	color: #898989;
}

.p-tabview-nav {
	display: flex;
	flex-wrap: wrap;
}

body .p-table .p-table-tbody>tr>td {
	padding: 8px !important;
}

body .p-overlaypanel {
	background-color: lightgray;
	box-shadow: 14px 13px 21px -2px rgba(0, 0, 0, 0.54);

}

body .p-menu .p-menuitem-link:not(.p-state-disabled):hover .p-menuitem-text {
	color: #fff !important;
}

body .p-menu .p-menuitem-link {
	font-weight: normal;
}

html .p-overlaypanel .p-overlaypanel-content,
body .p-overlaypanel .p-overlaypanel-content {
	padding: 0.571rem 1rem !important;
}

html .p-dialog .p-dialog-content,
body .p-dialog .p-dialog-content {
	padding: 1.5rem 1.5rem;
	overflow: scroll;
	//display: flex;

}

	.publish-confirm-modal {
	  min-height: 348;
	  height: 100%;
	  .title{

	  }
	  .publish-confirm-content{
		margin-top: 24px;
		display: flex;
		flex-flow: column;
		gap: 12px;
		width: 100%;
		input{
			pointer-events: none;
		  text-overflow: ellipsis;
		  border: 1px solid var(--theme-3-blue, #54A0FF);
		background: var(--States-Selected, #F3FBFF);
		overflow: hidden;
		color: #000;
		text-overflow: ellipsis;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px; /* 128.571% */
		letter-spacing: 0.4px;
		}
	  }
	  
	}

	.app-table{
		th{
			color: #000;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 18px; /* 128.571% */
letter-spacing: 0.2px;
		}
		div.checkbox{
			border: 1px solid #8F918D;
		// 	border-width: 0;
		// 	background-color: #00C29B;
			border-radius: 4px;
		}
		.kf-icon-checkbox-check{
			background: #00C29B !important;
			color: #000 !important;
			font-weight: 600;
			font-size: 19px;
			border-radius: 4px;
		}
	}


	.app-api-res-message{
		position: fixed;
    top: 20px;
    right: 12px;
    max-width: 400px;
	min-height: 60px;
	height: auto !important;
	z-index: 999;

	p-messages{
		height: auto !important;
	}
	}